// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.about-top-wrapper {
    padding: 4vw 8vw 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.about-top {
    align-self: flex-start;
    width: 44vw;
    padding: 0 2vw;
}

.about-top-wrapper img {
    align-self: flex-start;
    width: 20vw;
    padding: 0 4vw;
    
}

.about-middle-wrapper {
    padding: 10vw 12vw 4vw;
    position: relative;
    display: grid;
    place-items: center;
}
.about-middle-text {
    position: absolute;
    text-align: right;
    top: 0vw;
    right: 0;
    padding-right: 12vw;
    width: 40vw;
}

.about-middle-wrapper img {
    width: 72vw;
}

.about-bottom-wrapper {
    display: grid;
    place-items: center;
    text-align: center;
}

.about-bottom img {
    width: 80vw;
    padding: 1vw 0 6vw;
}`, "",{"version":3,"sources":["webpack://./src/styles/About.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,cAAc;;AAElB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,QAAQ;IACR,QAAQ;IACR,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["\n.about-top-wrapper {\n    padding: 4vw 8vw 2vw;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.about-top {\n    align-self: flex-start;\n    width: 44vw;\n    padding: 0 2vw;\n}\n\n.about-top-wrapper img {\n    align-self: flex-start;\n    width: 20vw;\n    padding: 0 4vw;\n    \n}\n\n.about-middle-wrapper {\n    padding: 10vw 12vw 4vw;\n    position: relative;\n    display: grid;\n    place-items: center;\n}\n.about-middle-text {\n    position: absolute;\n    text-align: right;\n    top: 0vw;\n    right: 0;\n    padding-right: 12vw;\n    width: 40vw;\n}\n\n.about-middle-wrapper img {\n    width: 72vw;\n}\n\n.about-bottom-wrapper {\n    display: grid;\n    place-items: center;\n    text-align: center;\n}\n\n.about-bottom img {\n    width: 80vw;\n    padding: 1vw 0 6vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
