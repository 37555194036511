// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
    position: relative;
    border-top: 1px solid #1f1f1f;
}

.footer-wrapper p {
    padding-left: 2vw;
    font-size: 1vw;
}

.footer-icons {
    position: absolute;
    right: 2vw;
    top: 72%;
    transform: translateY(-50%);
}

.footer-icons a {
    display: inline-block;
    margin-left: 1.4vw; /* Add some spacing between the icons */
  }

.footer-icons img {
    width: 1.4vw;
    transition: transform 0.5s ease;
}

.footer-icons img:hover {
    transform: scale(1.2); /* Increase the scale to make the image grow on hover */
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,qBAAqB;IACrB,kBAAkB,EAAE,uCAAuC;EAC7D;;AAEF;IACI,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,qBAAqB,EAAE,uDAAuD;AAClF","sourcesContent":[".footer-wrapper {\n    position: relative;\n    border-top: 1px solid #1f1f1f;\n}\n\n.footer-wrapper p {\n    padding-left: 2vw;\n    font-size: 1vw;\n}\n\n.footer-icons {\n    position: absolute;\n    right: 2vw;\n    top: 72%;\n    transform: translateY(-50%);\n}\n\n.footer-icons a {\n    display: inline-block;\n    margin-left: 1.4vw; /* Add some spacing between the icons */\n  }\n\n.footer-icons img {\n    width: 1.4vw;\n    transition: transform 0.5s ease;\n}\n\n.footer-icons img:hover {\n    transform: scale(1.2); /* Increase the scale to make the image grow on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
