// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.design-card-container {
    position: relative;
    width: 36vw;
    height: 18vw;
    border: 1px solid #1f1f1f;
    margin: 4vw 2vw 6vw;
}

.design-card-image {
    width: 36vw;
}

.design-card-image img {
    width: 100%;
    height: 50%;
}

.design-card-text-container {
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
}
.design-card-text {
    background-color: #ebe4ce;
    position: absolute;
    border: 1px solid #1f1f1f;
    padding: 1vw 4vw;
    top: 50%;
    left: 50%;
    width: 16vw;
    height: auto;
    margin-top: 7vw; /* Half of child container's height */
    margin-left: -12vw; /* Half of child container's width */
    transition: transform 0.5s ease;
  }



 .design-card-text:hover {
    transform: scale(1.05); /* Increase the scale to make the image grow on hover */
}

.design-card-text p {
    text-align: center;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/DesignCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,eAAe,EAAE,qCAAqC;IACtD,kBAAkB,EAAE,oCAAoC;IACxD,+BAA+B;EACjC;;;;CAID;IACG,sBAAsB,EAAE,uDAAuD;AACnF;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".design-card-container {\n    position: relative;\n    width: 36vw;\n    height: 18vw;\n    border: 1px solid #1f1f1f;\n    margin: 4vw 2vw 6vw;\n}\n\n.design-card-image {\n    width: 36vw;\n}\n\n.design-card-image img {\n    width: 100%;\n    height: 50%;\n}\n\n.design-card-text-container {\n    width: fit-content;\n    height: auto;\n}\n.design-card-text {\n    background-color: #ebe4ce;\n    position: absolute;\n    border: 1px solid #1f1f1f;\n    padding: 1vw 4vw;\n    top: 50%;\n    left: 50%;\n    width: 16vw;\n    height: auto;\n    margin-top: 7vw; /* Half of child container's height */\n    margin-left: -12vw; /* Half of child container's width */\n    transition: transform 0.5s ease;\n  }\n\n\n\n .design-card-text:hover {\n    transform: scale(1.05); /* Increase the scale to make the image grow on hover */\n}\n\n.design-card-text p {\n    text-align: center;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
