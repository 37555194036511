// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.design-page {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 8vw;
}

.design-wrapper {
    width: 72vw;
    margin: auto;
    display: grid;
    place-items: center;
  }
  
  .design-images-wrapper {
    display: flex;
    place-items: center;
    flex-wrap: wrap;
    
  }
  
  .design-images-wrapper img {
    max-width: 100%;
    margin: 2vw 0;
    
  }

  .design-bottom {
    padding-bottom: 2vw;
  }

  .design-external-link {
    width: 72vw;
    border: 1px solid #1f1f1f;
    text-align: center;
    padding: 2vw 0;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Design.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;;EAEjB;;EAEA;IACE,eAAe;IACf,aAAa;;EAEf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":[".design-page {\n    width: 100%;\n    overflow-y: auto;\n    padding-bottom: 8vw;\n}\n\n.design-wrapper {\n    width: 72vw;\n    margin: auto;\n    display: grid;\n    place-items: center;\n  }\n  \n  .design-images-wrapper {\n    display: flex;\n    place-items: center;\n    flex-wrap: wrap;\n    \n  }\n  \n  .design-images-wrapper img {\n    max-width: 100%;\n    margin: 2vw 0;\n    \n  }\n\n  .design-bottom {\n    padding-bottom: 2vw;\n  }\n\n  .design-external-link {\n    width: 72vw;\n    border: 1px solid #1f1f1f;\n    text-align: center;\n    padding: 2vw 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
