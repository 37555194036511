// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 2vw;
    max-width: 40vw;
    margin: 0 auto;
    border-radius: 4vw;
}

.navbar-item {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    text-decoration: none;
    color: #1f1f1f
}

.navbar-item:visited {
   color: #1f1f1f
}

.navbar-item:active {
    color: #1f1f1f;
}
  
.navbar-item img{
    width: 4vw;
    height: auto;
    transition: transform 0.5s ease;
}

.navbar-item:hover img {
  transform: scale(1.2);
}

.navbar-item-text {
  font-size: 1.5vw;
  position: relative;
  display: inline-block; /* Ensure the width of the pseudo-element matches the text */
}

.navbar-item-text::after {
  content: '';
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%; /* Make the underline the same width as the text */
  height: 0.5px;
  background-color: #1f1f1f;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease;
}

.navbar-item-text:hover::after {
  transform: scaleX(1);
}`, "",{"version":3,"sources":["webpack://./src/styles/Nav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB;AACJ;;AAEA;GACG;AACH;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,+BAA+B;AACnC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB,EAAE,4DAA4D;AACrF;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,OAAO;EACP,WAAW,EAAE,kDAAkD;EAC/D,aAAa;EACb,yBAAyB;EACzB,oBAAoB;EACpB,sBAAsB;EACtB,+BAA+B;AACjC;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".navbar {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    background-color: transparent;\n    padding: 2vw;\n    max-width: 40vw;\n    margin: 0 auto;\n    border-radius: 4vw;\n}\n\n.navbar-item {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    position: relative;\n    text-decoration: none;\n    color: #1f1f1f\n}\n\n.navbar-item:visited {\n   color: #1f1f1f\n}\n\n.navbar-item:active {\n    color: #1f1f1f;\n}\n  \n.navbar-item img{\n    width: 4vw;\n    height: auto;\n    transition: transform 0.5s ease;\n}\n\n.navbar-item:hover img {\n  transform: scale(1.2);\n}\n\n.navbar-item-text {\n  font-size: 1.5vw;\n  position: relative;\n  display: inline-block; /* Ensure the width of the pseudo-element matches the text */\n}\n\n.navbar-item-text::after {\n  content: '';\n  position: absolute;\n  bottom: 10%;\n  left: 0;\n  width: 100%; /* Make the underline the same width as the text */\n  height: 0.5px;\n  background-color: #1f1f1f;\n  transform: scaleX(0);\n  transform-origin: left;\n  transition: transform 0.5s ease;\n}\n\n.navbar-item-text:hover::after {\n  transform: scaleX(1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
