// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrolling-container {
    width: 200%;
    overflow: hidden;
    border-top: 1px solid #1f1f1f;
    border-bottom: 1px solid #1f1f1f;
}

.scrolling-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    animation: scroll 10s linear infinite;
}

.scrolling-text img {
    width: 4vw;
    height: auto;
}

.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 206px;
    overflow-x: hidden;
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/ScrollingText.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,6BAA6B;IAC7B,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,qCAAqC;AACzC;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":[".scrolling-container {\n    width: 200%;\n    overflow: hidden;\n    border-top: 1px solid #1f1f1f;\n    border-bottom: 1px solid #1f1f1f;\n}\n\n.scrolling-text {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    animation: scroll 10s linear infinite;\n}\n\n.scrolling-text img {\n    width: 4vw;\n    height: auto;\n}\n\n.marquee {\n    position: relative;\n    width: 100vw;\n    max-width: 100%;\n    height: 206px;\n    overflow-x: hidden;\n  }\n  \n  .track {\n    position: absolute;\n    white-space: nowrap;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
