// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.app-container {
  background-color: #ebe4ce;
  min-height: 100vh;
  position: relative;
}

.navbar-container {
  top: 0px;
  z-index: 1000000000000;
  position: sticky;
}

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

h1 {
  color: #1f1f1f;
  font-family: 'kepler-std-semicondensed', serif;
  font-size: 3.5vw;
  font-weight: 300;
}

h2{
  color: #1f1f1f;
  font-family: 'kepler-std-semicondensed', serif;
  font-size: 4vw;
  font-style: italic;
  font-weight: 300;
  line-height: 0;
}

h3{
  color: #1f1f1f;
  font-family: 'kepler-std-semicondensed', serif;
  font-size: 4vw;
  font-style: normal;
  font-weight: 300;
  line-height: .25;
  text-transform: uppercase;
}

h4 {
  color: #1f1f1f;
  text-align: center;
  font-family: 'kepler-std-semicondensed', serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p {
  color: #1f1f1f;
  font-family: 'Lekton', sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
}

a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,QAAQ;EACR,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,cAAc;EACd,8CAA8C;EAC9C,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,8CAA8C;EAC9C,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,8CAA8C;EAC9C,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,8CAA8C;EAC9C,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.app-container {\n  background-color: #ebe4ce;\n  min-height: 100vh;\n  position: relative;\n}\n\n.navbar-container {\n  top: 0px;\n  z-index: 1000000000000;\n  position: sticky;\n}\n\n.footer-container {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n\nh1 {\n  color: #1f1f1f;\n  font-family: 'kepler-std-semicondensed', serif;\n  font-size: 3.5vw;\n  font-weight: 300;\n}\n\nh2{\n  color: #1f1f1f;\n  font-family: 'kepler-std-semicondensed', serif;\n  font-size: 4vw;\n  font-style: italic;\n  font-weight: 300;\n  line-height: 0;\n}\n\nh3{\n  color: #1f1f1f;\n  font-family: 'kepler-std-semicondensed', serif;\n  font-size: 4vw;\n  font-style: normal;\n  font-weight: 300;\n  line-height: .25;\n  text-transform: uppercase;\n}\n\nh4 {\n  color: #1f1f1f;\n  text-align: center;\n  font-family: 'kepler-std-semicondensed', serif;\n  font-size: 3vw;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\np {\n  color: #1f1f1f;\n  font-family: 'Lekton', sans-serif;\n  font-size: 1.5vw;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1.25;\n}\n\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
