// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-top-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
}

.work-top img { 
    width: 60vw;
}

.client-work{
    position: absolute;
    right: 8vw;
    bottom: 4vw;
    
}
.client-work img {
    width: 20vw;
}

.personal-projects{
    position: absolute;
    left: 8vw;
    top: 15vw;
}

.personal-projects img {
    width: 20vw;
}

.work-middle-wrapper {
    display: grid;
    place-items: center;
    text-align: center;
    padding: 4vw 0;
}

.work-middle {
    width: 72vw;
    padding: 2vw 0;
    border: 1px solid #1f1f1f;
}

.work-middle h2 {
    font-size: 3vw;
}

.work-bottom-wrapper {
    width: 100%;
    padding-bottom: 8vw;
  }
  
  .design-card-col {  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 100000;
  }
  
  .design-card-row {
    display: flex;
    flex-direction: row;
    z-index: 100;
  }`, "",{"version":3,"sources":["webpack://./src/styles/Work.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;;AAEf;AACA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".work-top-wrapper {\n    position: relative;\n    width: 100%;\n    display: grid;\n    place-items: center;\n    text-align: center;\n}\n\n.work-top img { \n    width: 60vw;\n}\n\n.client-work{\n    position: absolute;\n    right: 8vw;\n    bottom: 4vw;\n    \n}\n.client-work img {\n    width: 20vw;\n}\n\n.personal-projects{\n    position: absolute;\n    left: 8vw;\n    top: 15vw;\n}\n\n.personal-projects img {\n    width: 20vw;\n}\n\n.work-middle-wrapper {\n    display: grid;\n    place-items: center;\n    text-align: center;\n    padding: 4vw 0;\n}\n\n.work-middle {\n    width: 72vw;\n    padding: 2vw 0;\n    border: 1px solid #1f1f1f;\n}\n\n.work-middle h2 {\n    font-size: 3vw;\n}\n\n.work-bottom-wrapper {\n    width: 100%;\n    padding-bottom: 8vw;\n  }\n  \n  .design-card-col {  \n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n    z-index: 100000;\n  }\n  \n  .design-card-row {\n    display: flex;\n    flex-direction: row;\n    z-index: 100;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
